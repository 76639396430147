import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

interface DropdownProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
}

export const Dropdown: React.FC<DropdownProps> = ({ trigger, children }) => {
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.DropdownMenuTrigger asChild>
        {trigger}
      </DropdownMenu.DropdownMenuTrigger>
      <DropdownMenu.Content>{children}</DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
