import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import Img from 'src/assets/icons/contacts.svg';
import { UseFormControl } from 'src/types';
import { TextArea, InputText, Button } from 'src/components';
import { FormInputNameContacts } from 'src/features/core/auth/types';

// interface ContactsContainerProps {
//   controllerContacts: UseFormControl;
//   onSubmit: () => void;
// }

export const ContactsContainer = () =>
  //     {
  // //   controllerContacts,
  // //   onSubmit,
  // }: ContactsContainerProps
  {
    return (
      <AuthWrapper isHomepage={false}>
        <div className="w-full container mx-auto pt-[54px] pb-[100px] font-dmsans">
          <h1 className="text-primary-500 text-[37px] font-bold mb-[70px]">
            Contato e suporte
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-[1fr_540px] border-b-2 text-neutro-w-100 pb-[70px] mb-[70px]">
            <div>
              <h2 className="text-[21px] font-normal mb-[12px] text-neutro-b-400">
                Endereço - Banca do Ingresso
              </h2>
              <p className="text-neutro-w-600 mb-[60px]">
                Perimetral Leste, 577, Santa Fé do Sul - SP, Brasil
              </p>
              {/* <h2 className="text-[21px] font-normal mb-[12px] text-neutro-b-400">
                Telefone
              </h2>
              <p className="text-neutro-w-600 mb-[60px]">+55 (17) 3631-4336</p> */}
              <h2 className="text-[21px] font-normal mb-[12px] text-neutro-b-400">
                Horário de funcionamento
              </h2>
              <p className="text-neutro-w-600 mb-[12px]">
                Seg-Sex das 08:00 às 18:00
              </p>
              <p className="text-neutro-w-600 mb-[60px]">
                Horário de almoço das 12:00 às 13:00
              </p>
              <h2 className="text-[21px] font-normal mb-[12px] text-neutro-b-400">
                Acesso ao atendimento ao cliente
              </h2>
              <p className="text-neutro-w-600 mb-[20px]">
                <a
                  href="https://suporte.bancadoingresso.com.br/open"
                  className="text-alert-info-soft"
                  target="_blanck"
                >
                  Suporte ao cliente
                </a>
              </p>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3744.1642698132528!2d-50.92262858465937!3d-20.210493786447294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9499c6acf9e75c7b%3A0x7d344c33ef920a6a!2sBanca%20do%20Ingresso!5e0!3m2!1spt-BR!2sbr!4v1673005135722!5m2!1spt-BR!2sbr"
                height="450"
                style={{ border: 0 }}
                className="w-full lg:w-[540px]"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          {/* <div>


          <div className="flex justify-start items-start mb-[70px]">
            <img src={Img} alt="Fale conosco" className="mr-[22px]" />
            <h3 className="text-[28px] font-normal mb-[12px] text-neutro-b-400">
              Fale com a gente!
              <br />
              Envie o que você quiser. Perguntas, críticas, elogios, dúvidas...
            </h3>
          </div>
          <div className="lg:w-[475px]">
            <div className="mb-[50px]">
              <InputText
                name="name"
                label="Seu nome"
                placeholder="Maria da Silva"
                className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                value={controllerContacts.formData[FormInputNameContacts.name]}
                onChange={(e) =>
                  controllerContacts.onChangeFormInput(
                    FormInputNameContacts.name
                  )(e.target.value)
                }
                error={
                  controllerContacts.formErrors.name &&
                  controllerContacts.formErrors.name[0]
                }
              />
            </div>
            <div className="mb-[50px]">
              <InputText
                name="email"
                label="Seu melhor e-mail"
                placeholder="exemplo@email.com.br"
                className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                value={controllerContacts.formData[FormInputNameContacts.email]}
                onChange={(e) =>
                  controllerContacts.onChangeFormInput(
                    FormInputNameContacts.email
                  )(e.target.value)
                }
                error={
                  controllerContacts.formErrors.email &&
                  controllerContacts.formErrors.email[0]
                }
              />
            </div>
            <div className="mb-[50px]">
              <TextArea
                name="message"
                label="Sua mensagem"
                placeholder="Digite aqui a sua mensagem"
                maxLength={100}
                rows={6}
                value={
                  controllerContacts.formData[FormInputNameContacts.message]
                }
                onChange={(e) =>
                  controllerContacts.onChangeFormInput(
                    FormInputNameContacts.message
                  )(e.target.value)
                }
                error={
                  controllerContacts.formErrors.message &&
                  controllerContacts.formErrors.message[0]
                }
              />
            </div>
            <div className="mb-[50px]">
              <Button
                type="button"
                title="Enviar"
                buttonStyle="primary"
                size="xlg"
                className="w-[230px]"
                disabled={
                  controllerContacts.formData[FormInputNameContacts.name] ===
                    '' ||
                  controllerContacts.formData[FormInputNameContacts.email] ===
                    '' ||
                  controllerContacts.formData[FormInputNameContacts.message] ===
                    ''
                }
                onClick={() => onSubmit()}
              />
            </div>
          </div>
        </div> */}
        </div>
      </AuthWrapper>
    );
  };
