import React from 'react';
import SupportIcon from 'src/assets/icons/supportIcon.svg';

interface SupportBottonProps {
  onCLick: () => void;
}

export const SupportBotton: React.FC<SupportBottonProps> = ({
  onCLick,
}): JSX.Element => {
  return (
    <div
      className="fixed bottom-5 right-5 border font-semibold border-gray-200 bg-white text-[#ce0707] flex items-center justify-center rounded-full shadow-xl transition-all cursor-pointer hover:bg-blue-100
        h-12 w-40 sm:h-14 sm:w-52 md:h-14 md:w-52
        space-x-2 sm:space-x-3 px-2 sm:px-4"
      onClick={onCLick}
    >
      <img src={SupportIcon} alt="Suporte" className="h-5 w-5 sm:h-6 sm:w-6" />
      <span className="text-xs sm:text-sm md:text-base">Precisa de ajuda?</span>
    </div>
  );
};
