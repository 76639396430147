// eslint-disable-next-line no-shadow
enum CardType {
  MASTER_CARD,
  VISA,
  AMERICAN_EXPRESS,
  DINERS_CLUB,
  DISCOVER,
  EN_ROUTE,
  JCB,
  VOYAGER,
  HIPER_CARD,
  AURA,
  ELO,
}
export default CardType;
