// import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
// import { FormInputNameContacts } from 'src/features/core/auth/types';
// import validators from 'src/helpers/validators';
// import useForm from 'src/hooks/useForm';
// import ContactUs from 'src/model/ContactUs';
// import { setLoading } from 'src/redux/loading/loadingSlice';
// import { api } from 'src/services/api';
// import { UseFormControl } from 'src/types';
import { ContactsContainer } from './ui';

export const ContactsScreen = () => {
  //   const dispatch = useDispatch();

  //   const {
  //     formData: formDataContacts,
  //     formErrors: formErrorsContacts,
  //     setErrors: setErrorsContacts,
  //     onChangeFormInput: onChangeFormInputContacts,
  //     isFormValid: isFormValidContacts,
  //     resetForm: resetFormContacts,
  //   } = useForm({
  //     initialData: {
  //       name: '',
  //       email: '',
  //       message: '',
  //     },
  //     validators: {
  //       name: [validators.required],
  //       email: [validators.required, validators.email],
  //       message: [validators.required],
  //     },
  //     formatters: {},
  //   });

  //   const handleSubmitContact = async (): Promise<void> => {
  //     if (isFormValidContacts()) {
  //       try {
  //         dispatch(setLoading(true));
  //         const contactUs: ContactUs = {
  //           name: formDataContacts[FormInputNameContacts.name],
  //           email: formDataContacts[FormInputNameContacts.email],
  //           message: formDataContacts[FormInputNameContacts.message],
  //         };
  //         await api.post('/auth/contact-us', contactUs);
  //         resetFormContacts();
  //         dispatch(setLoading(false));
  //         toast.success('Mensagem enviada com sucesso!');
  //       } catch (error) {
  //         dispatch(setLoading(false));
  //       }
  //     }
  //   };

  //   const controllerContacts: UseFormControl = {
  //     formData: formDataContacts,
  //     formErrors: formErrorsContacts,
  //     setErrors: setErrorsContacts,
  //     onChangeFormInput: onChangeFormInputContacts,
  //     isFormValid: isFormValidContacts,
  //     resetForm: resetFormContacts,
  //   };
  return (
    <>
      <ContactsContainer
      // controllerContacts={controllerContacts}
      // onSubmit={handleSubmitContact}
      />
    </>
  );
};
